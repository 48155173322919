.project {
  display: flex;
  align-items: flex-start; 
  margin-bottom: 50px; 
  width: 100%;
  padding: 0px;
}

.project-link {
  width: 30%;
  margin: 20px;
}
.project-image {
  /* min-width: 350px;  */
  /* width: 100%; */
  /* height: auto; */
  margin: 0 20px 0 0; 
  border-radius: 8px;
}
.project-info {
  display: flex;
  flex-direction: column;
  margin: 20px 30px 20px 0;
  /* flex-grow: 1;   */
  /*expand and take up available space */
  width: 60%;
}
.project-info h2 {
  font-size: 28px; 
  margin: 0; 
  width: 40%;
}
.title-date {
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: space-between; 
  width: 100%;
}
.title-date h2 {
  width: auto;
  font-weight: 700;
}
.project-date {
  margin-left: auto; 
  white-space: nowrap; 
  font-weight: bold;
}
.project-description {
  margin-top: 10px; 
}
.needs-work {
  font-weight: bold;
}
.content-section p {
  margin-bottom: 0; 
  font-weight: bold;
  font-size: 21px;
}

.ul-list {
  margin-top: 0; 
  padding-left: 20px; 
}


/*  Mobile  */

@media (max-width: 768px) {
  .project {
      flex-direction: column; 
      align-items: center; 
  }
  .project-link {
    width: 100%;
    /* margin: 20px; */
  }

  .project-image {
      display: block; 
      width: 90%; 
      margin: 20px auto 0 auto;
      /* margin: 0 0 20px 0;  */
  }

  .project-info {
      width: 80%; 
      margin-bottom: 40px;
  }

  .title-date {
      flex-direction: column;
      
  }
  .project-info h2, .project-date {
      width: 100%; 
      display: block;
      text-align: center; 
  }

  .project-date {
      margin-top: 10px;
      align-items: flex-end; 
  }
  .more-margin {
    margin-bottom: 200px; 
    padding-bottom: 60px;
  }
}